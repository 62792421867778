// Overlay
.loader-overlay {
    position: fixed;
    top: 107px;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1100;
    background-color: rgba(0, 0, 0, 0.2);
}

// Loading animation
.loading-dots {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;

    div:first-child {
        color: rgba($color: $black, $alpha: 0.5);
    }

    &--dot {
        animation: dot-keyframes 1.5s infinite ease-in-out;
        background-color: #000;
        border-radius: 10px;
        display: inline-block;
        height: 10px;
        width: 10px;
        margin: 0 2px;

        &:nth-child(2) {
            animation-delay: .5s
        }

        &:nth-child(3) {
            animation-delay: 1s
        }
    }

    @keyframes dot-keyframes {
        0% {
            opacity: .4;
            transform: scale(1, 1)
        }

        50% {
            opacity: 1;
            transform: scale(1.2, 1.2)
        }

        100% {
            opacity: .4;
            transform: scale(1, 1)
        }
    }
}