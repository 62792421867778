.modal {
    position: fixed;
    top: 20vh;
    left: 5%;
    width: 90%;
    background-color: white;
    padding: 1rem;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 1101;
    animation: slide-down 300ms ease-out forwards;

    button[type="close"] {
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: unset;
        border: unset;
        cursor: pointer;
    }
}

@media (min-width: 768px) {
    .modal {
        width: 40rem;
        left: calc(50% - 20rem);
    }
}

@keyframes slide-down {
    from {
        opacity: 0;
        transform: translateY(-2rem);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}